import { Themes } from '#/types/enums';
import { selectSiteTheme } from '#reducers/config';
import { useSelector } from 'react-redux';
import Logo from '../components/other/logo/Logo';
import './fallback.scss';

const NovaFallback = () => (
  <div className="fallback">
    <Logo isDark={useSelector(selectSiteTheme) === Themes.Light} isLoading={true}/>
    <span className='loader'></span>
  </div>
);

export default NovaFallback;
