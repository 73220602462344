import React, { ReactElement, useState, FormEventHandler } from 'react';
import './nova-text-input.scss';
import Tooltip from '#/elements/Tooltip';

// Helpers
import { toAllCaps, toDashCase } from '#/util';
import { NovaElementsSizes } from '#/types/enums';

export interface PropsNovaTextInput {
  placeholder?: string,
  placeholderParam?: string,
  value?: string,
  writeOnce?: boolean,
  hideInput?: boolean,
  required?: boolean,
  details?: string,
  detailsError?: string,
  name?: string,
  translation?: string,
  label?: string | ReactElement,
  hideLabel?: boolean,
  onBlur?: Function,
  onFocus?: Function,
  onChange?: Function,
  onInput?: FormEventHandler<HTMLInputElement>,
  onKeyDown?: Function, 
  hiddenValue?: boolean,
  focused?: boolean,
  disabled?: boolean,
  fieldKey?: string,
  className?: string,
  errorMessages?: Array<string>,
  footer?: string | JSX.Element,
  hasEyeIcon?: boolean,
  size?: NovaElementsSizes,
  withBorderRadius?: boolean,
  customIcon?: ReactElement | null,
  postText?: string,
  maxLength?: number,
}

const hasEyeIconIf = (field: PropsNovaTextInput) => {
  let eyeIconsExists = false;
  // eslint-disable-next-line no-mixed-operators
  if (field.name && ['password', 'confirm_password', 'Code'].includes(field.name) || field.hasEyeIcon) {
    eyeIconsExists = true;
  }
  return eyeIconsExists;
};

const NovaTextInput = (props: PropsNovaTextInput) => {
  const {
    placeholder: propPlaceholder = '',
    placeholderParam = '',
    value = '',
    name = '',
    fieldKey = '',
    className = 'primary',
    translation = 'NOVA_TEXT_INPUT',
    label = '',
    details = '',
    detailsError = '',
    hideLabel = false,
    hiddenValue: hiddenValueProp = true,
    writeOnce = false,
    focused = false,
    required = false,
    hideInput = false,
    disabled = false,
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    onInput = () => {},
    onKeyDown = () => {},
    errorMessages = [],
    hasEyeIcon = hasEyeIconIf(props),
    footer = '',
    size = NovaElementsSizes.Medium,
    withBorderRadius = false,
    customIcon = null,
    postText = '',
    maxLength,
  } = props;

  const [hiddenValue, setHiddenValue] = useState(hiddenValueProp);
  const [hasFocus, setHasFocus] = useState(focused);
  const isDisabled = (writeOnce && !!value.length) || disabled;

  const placeholder = _t(
    propPlaceholder,
    `${translation}.${toAllCaps(name)}_PLACEHOLDER`,
    placeholderParam,
  );

  const handleOnFocus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setHasFocus(true);
    onFocus(e);
  };
  const handleOnBlur = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setHasFocus(false);
    onBlur(e);
  };

  const inputProps = {
    name: fieldKey || toDashCase(name),
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
    onChange,
    onKeyDown,
    value,
  };

  const renderLabel = () => (!hideLabel ? (
    <div className="field-label">
      <span>{ label }</span>
      { required ? <span className="form-required">*</span> : '' }
      { details && <div className="field-details">{ details }</div> }
      { detailsError ? <div className="field-details-error">{ detailsError }</div> : null }
    </div>
  ) : null);
    
  const renderInput = () => (
    <div className={`${hideInput ? 'hide-show-password' : ''} input-holder`}>
      {/* @ts-ignore */}
      <input
        maxLength={maxLength}
        type={hideInput && hiddenValue ? 'password' : 'text'}
        placeholder={placeholder}
        autoComplete={hideInput && hiddenValue ? 'off' : 'on'}
        disabled={isDisabled}
        className={`nova-input-${size} ${writeOnce ? 'writeOnce' : ''} ${withBorderRadius ? 'nova-with-border-radius' : ''}`}
        onInput={onInput}
        {...inputProps}
      />
      { postText && (<span className='post-text'>{ postText }</span>) }
      { hasEyeIcon && (
        <i
          onClick={() => setHiddenValue(!hiddenValue)}
          className={hiddenValue ? 'far fa-eye' : 'far fa-eye-slash'}
        />
      )}
      { customIcon }
    </div>
  );

  const renderFooter = () => {
    return footer
  }
  
  return (
    <label className={`nova-form-field nova-text-input ${className}`}>
      { renderLabel() }
      <div className={`nova-field-content ${disabled ? 'nova-field-content-disabled' : ''}`}>
        <Tooltip messages={errorMessages} />
        { renderInput() }
        { renderFooter() }
      </div>
    </label>
  );
};

export default React.memo(NovaTextInput);
