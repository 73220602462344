import { novaToast, DefaultToastOptions } from '#/nova/components/other/toast/novaToast';
import { NotificationTrigger } from '#/types';
import {
  ConversionHistory,
  IPaymentResult,
  IPaymentStatus,
  ISelectedInstrument,
  NotificationEvent,
  OrderResult,
} from '#/types/interfaces';
import { toSpaces, capitalize, formatExponentialNumberToLocale } from '#/util';
import { DepositWithdraw, OrderStatus, PaymentStatus, TradeMode } from '#reducers/trade/enums';
import { ConversionStatus } from './types';

export const eventOrderToast = (
  order: OrderResult,
  notifications_settings: Array<NotificationTrigger>,
  instrumentsArray: ISelectedInstrument[] ,
) => { 
  const orderInstrument = instrumentsArray
    .find(instrument => instrument.instrument_id === order.instrument_id);
  const convertedOrderQuantity = order.quantity.toLocaleString(undefined, { maximumFractionDigits: 8 });
  const amountInstrument = order.quantity_mode === TradeMode.Quote ? orderInstrument?.quote_currency_id : orderInstrument?.base_currency_id
  const types = {
    [OrderStatus.Cancelled]: () => {
      if (notifications_settings.includes(NotificationTrigger.order_cancelled)) {
        novaToast.info(_t(`Order cancelled.`, 'NOVA_NOTIFICATIONS.ORDER_CANCELLED'), {
          [_t('ID', 'EXECUTE_ORDER.ID')]: order.order_id,
        });
      }
    },
    [OrderStatus.Expired]: () => {
      if (notifications_settings.includes(NotificationTrigger.order_cancelled)) {
        novaToast.notificationApi(_t(`Order expired.`, 'TOAST.ORDER_EXPIRED'), DefaultToastOptions.Error, {
          [_t('ID', 'EXECUTE_ORDER.ID')]: order.order_id,
        });
      }
    },
    [OrderStatus.New]: () => {
      if (notifications_settings.includes(NotificationTrigger.order_new)) {
        novaToast.success(_t(`Order created.`, 'TOAST.ORDER_CREATED'), {
          [_t('ID', 'EXECUTE_ORDER.ID')]: order.order_id,
          [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${convertedOrderQuantity} ${amountInstrument}`,
          // [_t('Price', 'EXECUTE_ORDER.PRICE')]: order.price,
        });
      }
    },
    [OrderStatus.Rejected]: () => {
      if (notifications_settings.includes(NotificationTrigger.order_rejected)) {
        novaToast.notificationApi(_t(`Order rejected.`, 'NOVA_NOTIFICATIONS.ORDER_REJECTED'), DefaultToastOptions.Error, {
          [_t('ID', 'EXECUTE_ORDER.ID')]: order.order_id,
          [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${convertedOrderQuantity} ${amountInstrument}`,
          [_t('Price', 'EXECUTE_ORDER.PRICE')]: `${order.price} ${orderInstrument?.quote_currency_id}`,
          [_t('Message', 'EXECUTE_ORDER.MESSAGE')]: toSpaces(order.message),
        });
      }
    },
    [OrderStatus.Completed]: () => {
      if (notifications_settings.includes(NotificationTrigger.order_completed)) {
        novaToast.notificationApi(_t(`Order completed.`, 'NOVA_NOTIFICATIONS.ORDER_COMPLETED'), DefaultToastOptions.Success, {
          [_t('ID', 'EXECUTE_ORDER.ID')]: order.order_id,
          [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${convertedOrderQuantity} ${amountInstrument}`,
          [_t('Price', 'EXECUTE_ORDER.PRICE')]: `${order.price} ${orderInstrument?.quote_currency_id}`,
        });
      }
    },
  }

  types[order.status]?.();
}

export const eventPaymentToast = (payment: IPaymentResult, notifications_settings: Array<NotificationTrigger>) => {
  if (payment.error_message) {
    novaToast.error(
      `${_t(payment.type, `GENERIC.${payment.type.toUpperCase()}`)} ${_t(payment.status, `PAYMENT.PAYMENT_${payment.status.toUpperCase()}`) || 'error'}.`,
      {
        [_t('ID', 'PAYMENT.ID')]: payment.payment_id,
        [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${formatExponentialNumberToLocale(payment.amount)} ${payment.currency_id}`,
        [_t('Status', 'PAYMENT.STATUS')]: capitalize(payment.status),
        [_t('Message', 'PAYMENT.MESSAGE')]: toSpaces(payment.error_message || payment.message || _t('Something went wrong..', 'PAYMENT.WENT_WRONG')),
      }
    );
  } else {
    const shouldShowNotification = [
      payment.status === IPaymentStatus.Completed && notifications_settings.includes(NotificationTrigger.payment_completed),
      payment.status === IPaymentStatus.New && notifications_settings.includes(NotificationTrigger.payment_new),
      payment.status === IPaymentStatus.Rejected && notifications_settings.includes(NotificationTrigger.payment_rejected),
    ];
    if (shouldShowNotification.some((_) => !!_)) {
      const typeOptions = {
        [DepositWithdraw.Deposit]: _t('Deposit', 'GENERIC.DEPOSIT'),
        [DepositWithdraw.Withdraw]: _t('Withdrawal', 'GENERIC.WITHDRAWAL'),
      };

      const statusOptions = {
        [PaymentStatus.New]: _t('New', 'NOVA_TRANSACTIONS.NEW'),
        [PaymentStatus.Rejected]: _t('Rejected', 'NOVA_TRANSACTIONS.REJECTED'),
        [PaymentStatus.Completed]: _t('Completed', 'NOVA_TRANSACTIONS.COMPLETED'),
        [PaymentStatus.Processing]: _t('Processing', 'NOVA_TRANSACTIONS.PROCESSING'),
        [PaymentStatus.Unconfirmed]: _t('Unconfirmed', 'NOVA_TRANSACTIONS.UNCONFIRMED'),
      };

      const type = typeOptions[payment.type] || payment.type;
      const status = statusOptions[payment.status] || payment.status;

      novaToast.notificationApi(`${type} ${status}.`, DefaultToastOptions.Success, {
        [_t('ID', 'PAYMENT.ID')]: payment.payment_id,
        [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${formatExponentialNumberToLocale(payment.amount)} ${payment.currency_id}`,
        [_t('Status', 'PAYMENT.STATUS')]: capitalize(status),
      });
    }
  }
}

export const eventCryptoDepositToast = (notifications_settings: Array<NotificationTrigger>) => {
  if (notifications_settings.includes(NotificationTrigger.CryptoAddressCreated)) {
    novaToast.info(_t('Deposit address generated', 'TOAST.DEPOSIT_ADDRESS_GENERATED'));
  }
};

export const eventCryptoDepositFiatToast = (notifications_settings: Array<NotificationTrigger>) => {
  if (notifications_settings.includes(NotificationTrigger.CryptoAddressCreated)) {
    novaToast.info(_t('Deposit info generated', 'TOAST.DEPOSIT_INFO_GENERATED'));
  }
};

export const eventNotificationToast = (notification: NotificationEvent) => {
  const types = {
    error: () => {
      novaToast.error(notification.title || 'Error', {
        noTitle: notification.body.replace(/Error: RRN: \w+, /, '')
      })
    },
    success: () => {
      novaToast.success(notification.title || 'Success', {
        noTitle: notification.body,
        [_t('Reference', 'NOTIFICATION.REFERENCE')]: notification.reference_nr,
      });
    },
    info: () => {
      novaToast.info(notification.title || 'Info', {
        noTitle: notification.body,
        [_t('Reference', 'NOTIFICATION.REFERENCE')]: notification.reference_nr,
      });
    },
  }

  types[notification.class] ? types[notification.class]() : novaToast.info(notification.body);
}

export const executeConversionToast = (conversion: ConversionHistory, notifications_settings: Array<NotificationTrigger>) => {
  const toastInfo = {
    [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${formatExponentialNumberToLocale(conversion.source_currency_amount)} ${conversion.source_currency_id}`,
    [_t('Price', 'EXECUTE_ORDER.PRICE')]: conversion.price,
    [_t('Fee', 'EXECUTE_ORDER.FEE')]: `${conversion.fee_currency_amount} ${conversion.fee_currency_id}`,
    [_t('Total', 'EXECUTE_ORDER.TOTAL')]: `${formatExponentialNumberToLocale(conversion.target_currency_amount)} ${conversion.target_currency_id}`,
    [_t('Status', 'EXECUTE_ORDER.STATUS')]: capitalize(conversion.status),
  };

  const types = {
    [ConversionStatus.New]: () => {
      if (notifications_settings.includes(NotificationTrigger.conversion_new)) {
        novaToast.success(`Conversion created.`, toastInfo);
      }
    },
    [ConversionStatus.Completed]: () => {
      if (notifications_settings.includes(NotificationTrigger.conversion_completed)) {
        novaToast.success(`Conversion executed.`, toastInfo);
      }
    },
  }

  types[conversion.status]?.();
}
