import { defaultFiatProperties, requiredAddressBookFiatFields, requiredDefaultFields } from "#/nova/components/other/wallet/wallet-deposit-withdraw/fiat/nova-wallet-withdraw/DefaultWithdrawalFields";
import { mxnFiatProperties, requiredMxnAddressbookFields, requiredMxnWalletFields } from "#/nova/components/other/wallet/wallet-deposit-withdraw/fiat/nova-wallet-withdraw/custom-fiat-fields/WithdrawFiatMxnFields";
import { arionFiatProperties, requiredAddressArionFields, requiredArionWalletFields } from "#/nova/components/other/wallet/wallet-deposit-withdraw/fiat/nova-wallet-withdraw/custom-fiat-fields/withdrawal-arion-fields.tsx/WithdrawFiatArionFields";
import { requiredAddressWireFields, requiredWireWalletFields, wireFiatProperties } from "#/nova/components/other/wallet/wallet-deposit-withdraw/fiat/nova-wallet-withdraw/custom-fiat-fields/withdrawal-wire-fields/WithdrawFiatWireFields";
import { EFiatCurrency, IDepositFiatFundingMethod, IsActive, PaymentProvider } from "#/types";


export const configWithdraw = {
  defaultFiatFeesIncluded: IsActive.On, //Do not change for fiat until the logic for the hook is written
  defaultCryptoFeesIncluded: IsActive.Off, //client's custom
}

export enum EFieldsType {
  Withdraw = 'withdraw',
  Addressbook = 'addressbook',
  FiatProperties = 'fiatProperties',
}

interface IGetFieldsFiat {
  fieldType: EFieldsType,
  selectedCurrency?: string,
  withdrawMethod?: string | null,
  psp_service_id?: string,
}

export const getFieldsFiat = ({ selectedCurrency = '', fieldType, withdrawMethod, psp_service_id }: IGetFieldsFiat): string[] => {
  const defaultFieldsConfig = {
    [EFieldsType.Addressbook]: requiredAddressBookFiatFields,
    [EFieldsType.Withdraw]: requiredDefaultFields,
    [EFieldsType.FiatProperties]: defaultFiatProperties,
  };

  const customFieldsConfig = {
    [EFiatCurrency.MXN]: {
      [EFieldsType.Addressbook]: requiredMxnAddressbookFields,
      [EFieldsType.Withdraw]: requiredMxnWalletFields,
      [EFieldsType.FiatProperties]: mxnFiatProperties,
    },
    [IDepositFiatFundingMethod.Wire]: {
      [EFieldsType.Addressbook]: requiredAddressWireFields,
      [EFieldsType.Withdraw]: requiredWireWalletFields,
      [EFieldsType.FiatProperties]: wireFiatProperties,
    },
    [PaymentProvider.ARION]: {
      [EFieldsType.Addressbook]: requiredAddressArionFields,
      [EFieldsType.Withdraw]: requiredArionWalletFields,
      [EFieldsType.FiatProperties]: arionFiatProperties,
    }
  };
  
  // ---- The order of the condition is very important depending on which property is more important
  // ---- If you change this order, also change it in the file NovaBankFields and WithdrawInfoFiat
  return (customFieldsConfig[selectedCurrency] && customFieldsConfig[selectedCurrency][fieldType])
    || (customFieldsConfig[psp_service_id as string] && customFieldsConfig[psp_service_id as string][fieldType])
    || (customFieldsConfig[withdrawMethod as string] && customFieldsConfig[withdrawMethod as string][fieldType])
    || defaultFieldsConfig[fieldType];
};
