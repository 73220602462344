export enum TimeInForce {
  GTC = 'gtc',
  FOK = 'fok',
  IOC = 'ioc',
  GTD = 'gtd',
  DAY = 'day',
}

export enum DepositWithdrawTypes {
  Deposit = 'Receive',
  Withdraw = 'Send',
}

export enum CryptoTypes {
  Crypto = 'crypto',
  Fiat = 'fiat',
}

export enum TradeSide {
  Sell = 'sell',
  Buy = 'buy',
}

export enum IsActive {
  On = 'on',
  Off = 'off',
}

export enum AccountType {
  Source = 'source_account',
  Destination = 'destination_account',
}

export enum TradeType {
  Market = 'market',
  Limit = 'limit',
  StopMarket = 'stop_market',
  StopLimit = 'stop_limit',
}

export enum TradeMode {
  Base = 'base',
  Quote = 'quote',
}

export enum OrdersType {
  Open = 'open',
  Closed = 'closed',
}

export enum ConversionStatus {
  New = 'new',
  Completed = 'completed',
  Rejected = 'rejected',
}

export enum OrdersGetTypes {
  Closed = 'closed',
  Open = 'open',
}

export enum OrderStatus {
  New = 'new',
  Pending = 'pending',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
  Expired = 'expired',
  Completed = 'completed',
}

export enum HistoryTabs {
  OrdersOpen = 'open',
  OrdersHistory = 'history',
  ConversionsHistory = 'conversions_history',
  AccountTransactions = 'account_transactions',
}

export enum DerivativesHistoryTabs {
  OpenPositions = 'open_positions',
  ClosedPosition = 'closed_positions',
  OpenOrders = 'open_orders',
  OrdersHistory = 'orders_history',
}

export enum DepositWithdraw {
  Deposit = 'deposit',
  Withdraw = 'withdrawal',
  None = '',
}

export enum CryptoAddressTagType {
  DestinationTag = 'destination_tag',
  MemoId = 'memo_id',
  Note = 'note',
  Tag = 'tag',
}

export enum Periodicity {
  Minute = 'minute',
  Minute5 = 'minute5',
  Minute15 = 'minute15',
  Minute30 = 'minute30',
  Hour = 'hour',
  Hour4 = 'hour4',
  Hour8 = 'hour8',
  Day = 'day',
  Week = 'weel',
  Month = 'month',
}

export enum SelectionTypes {
  CurrencyModalQuote = 'currencyModalQuote',
  CurrencyModalBase = 'currencyModalBase',
}

export enum CsvExportEndpoints {
  OrdersHistory = 'closed-orders',
  WalletTransactions = 'payments',
  VaultHistory = 'history',
  AccountTransactions = 'account-transactions',
  ConversionsHistory = 'conversions',
}

export enum AccountTransactionClass {
  Trade = 'trade',
  Fee = 'fee',
  Payment = 'payment',
  Reward = 'reward',
  Conversion = 'conversion',
  Manual = 'manual',
  ReferralReward = 'referral_reward'
}

export enum AccountTransactionType {
  Debit = 'debit',
  Credit = 'credit',
}

export enum PaymentStatus {
  New = 'new',
  Processing = 'processing',
  Rejected = 'rejected',
  Completed = 'completed',
  Unconfirmed = 'unconfirmed',
}

export enum PaymentApprovalStatus {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum FiatFields {
  BeneficiaryName = 'beneficiary_name',
  BeneficiaryAddress = 'beneficiary_address_line_1',
  BeneficiaryCity = 'beneficiary_city',
  BeneficiaryRegion = 'beneficiary_region',
  BeneficiaryCountry = 'beneficiary_country',
  BeneficiaryPostalCode = 'beneficiary_postal_code',
  BankName = 'bank_name',
  BeneficiaryAccountNumber = 'beneficiary_account_number',
  BankBic = 'bank_bic',
  RoutingNumber = 'routing_number',
  BankAddress = 'bank_address',
  BankCity = 'bank_city',
  BankRegion = 'bank_region',
  BankCountry = 'bank_country',
  BankPostalCode = 'bank_postal_code',
  Reference = 'reference',
  BeneficiaryTaxId = 'beneficiary_tax_id',
  Notes = 'notes',
}

export enum FiatWalletFields {
  Name = 'name',
  BankBic = 'fiat_bank_bic',
  BankName = 'fiat_bank_name',
  BankCountry = 'fiat_bank_country',
  BankAddress = 'fiat_bank_address',
  BankCity = 'fiat_bank_city',
  BeneficiaryName = 'fiat_beneficiary_name',
  BeneficiaryCity = 'fiat_beneficiary_city',
  BeneficiaryCountry = 'fiat_beneficiary_country',
  BeneficiaryAccountNumber = 'fiat_beneficiary_account_number',
  BeneficiaryAddress = 'fiat_beneficiary_address_line_1',
  Reference = 'fiat_reference',
  BeneficiaryTaxId = 'fiat_beneficiary_tax_id',

  // not required fields
  BankPostalCode = 'fiat_bank_postal_code',
  BeneficiaryRegion = 'fiat_beneficiary_region',
  BeneficiaryPostalCode = 'fiat_beneficiary_postal_code',
  RoutingNumber = 'fiat_routing_number',
  BankRegion = 'fiat_bank_region',
  BeneficiaryAddress2 = 'fiat_beneficiary_address_line_2',
  Notes = 'fiat_notes',
}
